@use '@angular/material' as mat;
@import 'styles-variables';

@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'styles-reset';

@include mat.core();

@import './themes/default-theme';

@import 'styles-reset.scss-theme';
@import './app/app/app.component.scss-theme';
@import './app/features/dashboard/dashboard/dashboard.component.scss-theme';
@import './app/features/listing/listing/listing.component.scss-theme';
@import './app/shared/components/side-menu-expansion-panel/side-menu-expansion-panel.component.scss-theme';

$custom-typography: mat.define-typography-config(
    $font-family: '"Montserrat", sans-serif;'
);
@include mat.core($custom-typography);

@mixin custom-components-theme($theme) {
    @include lynkd-pattern-styles-reset-theme($theme);
    @include lynkd-pattern-app-component-theme($theme);
    @include lynkd-pattern-dashboard-component-theme($theme);
    @include lynkd-pattern-listing-component-theme($theme);
    @include lynkd-pattern-side-menu-expansion-panel-component-theme($theme);
}

.default-theme {
    @include mat.all-component-themes($lynkd-pattern-theme);
    @include custom-components-theme($lynkd-pattern-theme);
}

body,
html {
    height: 100%;
    background-color: #333;
}

html {
    overflow: hidden !important;
}

mwl-gauge {
    width: 150px;
    height: 150px;
    display: block;
    padding: 10px;
}
mwl-gauge > .gauge > .dial {
    stroke: #eee;
    stroke-width: 2;
    fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value {
    stroke: rgb(47, 227, 255);
    stroke-width: 2;
    fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value-text {
    fill: rgb(47, 227, 255);
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1em;
}
.workbench-total-dialog-container .mat-dialog-container {
    padding: 0 !important;
}
.workbench-parameter-modal .mat-dialog-container {
    padding: 0 !important;
}

.settings-new-rule-modal .mat-dialog-container {
    padding: 0 !important;
}

.full-screen-modal .mat-dialog-container {
    width: 100%;
    height: 100%;
    background-color: #eee;
}
