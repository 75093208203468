@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '../../../../styles-variables';

@mixin lynkd-pattern-side-menu-expansion-panel-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);

    &.accordion-filters {
        .mat-expansion-panel-header {
            .mat-expansion-panel-header-title {
                color: mat.get-color-from-palette($primary, 600) !important;
            }

            svg {
                /* path {
                  fill: mat-color($primary, 600) !important;
                } */
            }

            &.mat-expanded {
                background-color: mat.get-color-from-palette($primary, 400) !important;

                &:hover {
                    background: mat.get-color-from-palette($primary, 400) !important;
                }

                svg {
                    path {
                        fill: white !important;
                    }
                }

                span {
                    color: white !important;
                }

                .mat-expansion-indicator {
                    &:after {
                        color: mat.get-color-from-palette($primary, 600) !important;
                    }
                }
            }
        }
    }


    .accordion-filters {
        .mat-expansion-panel-header {
            .mat-expansion-panel-header-title {
                color: mat.get-color-from-palette($primary, 600) !important;
            }

            svg {
                /* path {
                  fill: mat-color($primary, 600) !important;
                } */
            }

            &.mat-expanded {
                background-color: mat.get-color-from-palette($primary, 400) !important;

                &:hover {
                    background: mat.get-color-from-palette($primary, 400) !important;
                }

                svg {
                    path {
                        fill: white !important;
                    }
                }

                span {
                    color: white !important;
                }

                .mat-expansion-indicator {
                    &:after {
                        color: mat.get-color-from-palette($primary, 600) !important;
                    }
                }
            }
        }
    }

    .mat-expansion-panel {
        box-shadow: none !important;
        border-bottom: 0 solid mat.get-color-from-palette($accent, 300) !important;

        .mat-expansion-panel-header {
            .mat-expansion-panel-header-title {
                color: white !important;
            }

            &:hover {
                background-color: mat.get-color-from-palette($accent, 300) !important;
            }

            .mat-expansion-indicator {
                &:after {
                    color: mat.get-color-from-palette($primary, 500) !important;
                }
            }

            &.mat-expanded {
                background-color: mat.get-color-from-palette($accent, 200) !important;

                &:hover {
                    background: mat.get-color-from-palette($accent, 200) !important;
                }

                svg {
                    path {
                        fill: white !important;
                    }
                }

                span {
                    color: white !important;
                }

                .mat-expansion-indicator {
                    &:after {
                        color: white !important;
                    }
                }
            }
        }

        .mat-nav-list {
            .mat-list-item {
                color: mat.get-color-from-palette($primary, 600) !important;

                &:hover {
                    background-color: mat.get-color-from-palette($accent, 300) !important;
                }

                input {
                    border: solid 1px mat.get-color-from-palette($primary, 500);
                    background: mat.get-color-from-palette($accent, 200);
                    color: mat.get-color-from-palette($primary, 600);
                }
            }
        }
    }
}




//
//.icon {
//    position: absolute;
//    top: 23px;
//    right: -2px;
//    width: 20px;
//    transition: left ease 0.25s;
//}
//.icon.opened {
//    position: relative !important;
//    top: 0 !important;
//    left: 0 !important;
//}
//.mat-expansion-panel {
//    border-bottom-width: 1px !important;
//}
//
//::ng-deep .mat-expansion-panel {
//    border-bottom-width: 1px !important;
//}
//
//.mat-expansion-panel {
//    margin: 0;
//    border-radius: 0 !important;
//    border-bottom-width: 1px !important;
//    //border-bottom-width: 0;
//    background: transparent;
//    flex: 0 0 auto;
//
//    //.mat-expansion-panel-header {
//    //    position: relative;
//    //    padding: 0 10px 0 18px;
//    //    height: 64px;
//    //    font-size: 14px;
//    //
//    //    .mat-expansion-panel-header-title {
//    //        display: flex;
//    //        align-items: center;
//    //
//    //
//    //        span {
//    //            __<<ngThemingMigrationEscapedComment2>>__
//    //            line-height: 16px;
//    //            font-size: 14px;
//    //            font-weight: 700;
//    //            transition: opacity ease 0.75s;
//    //        }
//    //    }
//    //
//    //    ::ng-deep .mat-expansion-indicator {
//    //        position: absolute;
//    //        right: 18px;
//    //        height: 28px;
//    //    }
//    //}
//    //
//    //::ng-deep .mat-expansion-panel-body {
//    //    padding: 13px 0;
//    //}
//}

//::ng-deep .mat-expansion-panel.accordion-filters {
//    border-bottom-width: 1px !important;
//
//    &[disabled] {
//        cursor: pointer;
//    }
//
//    .mat-expansion-panel-header {
//        .mat-expansion-panel-header-title {
//            span {
//                font-size: 14px;
//                font-weight: 500;
//            }
//        }
//    }
//
//    ::ng-deep .mat-expansion-panel-content {
//        overflow-y: visible;
//        height: auto;
//    }
//
//    ::ng-deep .mat-expansion-panel-body {
//        padding: 0;
//    }
//}
//
//
//.opened {
//    ::ng-deep .mat-expansion-panel {
//        border-bottom-width: 1px !important;
//
//        ::ng-deep .mat-expansion-indicator {
//            display: block !important;
//        }
//    }
//
//    .mat-expansion-panel {
//        __<<ngThemingMigrationEscapedComment3>>__
//
//        &.mat-expanded {
//            flex: 0 1 auto;
//
//            ::ng-deep .mat-expansion-panel-content {
//                // height: 69vh;
//                height: calc(100% - 64px);
//                overflow-y: auto;
//            }
//        }
//
//        __<<ngThemingMigrationEscapedComment4>>__
//
//        &.mat-expanded {
//            __<<ngThemingMigrationEscapedComment5>>__
//
//            ::ng-deep .mat-expansion-panel-content {
//                .mat-expansion-panel {
//                    height: unset;
//
//                    .mat-nav-list {
//                        __<<ngThemingMigrationEscapedComment6>>__
//                    }
//                }
//            }
//        }
//
//        .mat-expansion-panel-header-title {
//            .icon {
//                position: relative !important;
//                top: 0 !important;
//                left: 0 !important;
//            }
//
//            span {
//                __<<ngThemingMigrationEscapedComment7>>__
//            }
//        }
//    }
//}
//&.accordion-filters {
//    position: relative;
//
//    .mat-expansion-panel {
//        border-bottom-width: 0 !important;
//
//        &[disabled] {
//            cursor: pointer;
//        }
//
//        .mat-expansion-panel-header {
//            .mat-expansion-panel-header-title {
//                span {
//                    font-size: 14px;
//                    font-weight: 500;
//                }
//            }
//        }
//
//        ::ng-deep .mat-expansion-panel-content {
//            overflow-y: visible;
//            height: auto;
//        }
//
//        ::ng-deep .mat-expansion-panel-body {
//            padding: 0;
//        }
//
//        .mat-nav-list {
//            padding-top: 27px;
//            padding-bottom: 37px;
//
//            .mat-list-item {
//                margin-top: -16px;
//                margin-bottom: 24px;
//
//                &.add-more-metadata {
//                    margin-top: -10px;
//                    margin-bottom: -20px !important;
//
//                    button {
//                        font-size: 12px;
//                    }
//                }
//
//                &:last-child {
//                    margin-bottom: 0;
//                }
//            }
//        }
//    }
//}

//.mat-expansion-panel {
//    margin: 0;
//    border-radius: 0 !important;
//    border-bottom-width: 0;
//    background: transparent;
//    flex: 0 0 auto;
//
//    .mat-expansion-panel-header {
//        position: relative;
//        padding: 0 10px 0 18px;
//        height: 64px;
//        font-size: 14px;
//
//        .mat-expansion-panel-header-title {
//            display: flex;
//            align-items: center;
//
//            svg {
//                position: absolute;
//                top: 23px;
//                __<<ngThemingMigrationEscapedComment8>>__
//                right: -2px;
//                width: 20px;
//                transition: left ease 0.25s;
//            }
//
//            span {
//                __<<ngThemingMigrationEscapedComment9>>__
//                line-height: 16px;
//                font-size: 14px;
//                font-weight: 700;
//                transition: opacity ease 0.75s;
//            }
//        }
//
//        ::ng-deep .mat-expansion-indicator {
//            display: none;
//            position: absolute;
//            right: 18px;
//            height: 28px;
//        }
//    }
//
//    ::ng-deep .mat-expansion-panel-body {
//        padding: 13px 0;
//    }
//}

//.opened {
//    ::ng-deep .mat-expansion-panel {
//        border-bottom-width: 1px !important;
//
//        ::ng-deep .mat-expansion-indicator {
//            display: block !important;
//        }
//    }
//}
