@use '@angular/material' as mat;

@mixin lynkd-pattern-listing-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  lynkd-pattern-listing {
    .toolbar-toggle {
      svg {
        path {
          fill: mat.get-color-from-palette($primary, 500);
        }
      }
    }

    .dashboard-toolbar {
      background-color: white;

      .filters-data {
        button {
          color: mat.get-color-from-palette($accent, 500);

          &[aria-expanded='true'] {
            background-color: mat.get-color-from-palette($accent, 500);
            color: white;

            svg {
              path {
                &:first-of-type {
                  fill: white;
                }
              }
            }
          }
        }
      }

      .mat-button-toggle-group {
        border: solid 1px mat.get-color-from-palette($primary, 500);
      }

      .mat-button-toggle {
        .mat-button-toggle-button {
          color: mat.get-color-from-palette($primary, 500);
        }

        &.mat-button-toggle-checked {
          background-color: mat.get-color-from-palette($primary, 300);

          .mat-button-toggle-button {
            color: mat.get-color-from-palette($primary, 400);
          }
        }

        + .mat-button-toggle {
          border-left: solid 1px mat.get-color-from-palette($primary, 400);
        }
      }

      input {
        border: solid 1px mat.get-color-from-palette($primary, 500);
        color: mat.get-color-from-palette($primary, 500);
      }

      .button-group {
        button {
          position: relative;

          &:before {
            position: absolute;
            top: 8px;
            left: 0;
            border-right: 1px solid mat.get-color-from-palette($accent, 600);
            height: 18px;
            content: '';
          }

          &:first-of-type {
            &:before {
              content: unset;
            }
          }
        }
      }

      button {
        color: mat.get-color-from-palette($primary, 600);

        &.mat-accent {
          &.mat-flat-button {
            color: white !important;
          }

          &.active {
            background-color: mat.get-color-from-palette($primary, 400);
            border-color: mat.get-color-from-palette($primary, 400) !important;
            color: mat.get-color-from-palette($accent, 500) !important;
          }
        }

        &.button-submit {
          &.clear {
            background-color: mat.get-color-from-palette($accent, 200);
          }
        }

        .mat-button-wrapper {
          display: flex;
          align-items: center;
        }

        &:first-child {
          &:after {
            border-right: 1px solid mat.get-color-from-palette($accent, 600);
          }
        }
      }

      &:first-of-type {
        border-bottom: 1px solid mat.get-color-from-palette($accent, 600) !important;
      }
    }

    a {
      color: mat.get-color-from-palette($primary, 500);

      &.toggled {
        svg {
          path {
            &.arrow-up {
              fill: mat.get-color-from-palette($primary, 500);
            }

            &.arrow-down {
              fill: white;
            }
          }
        }
      }
    }

    p {
      strong {
        color: mat.get-color-from-palette($primary, 500);
      }
    }

    .product-listing {
      .col-12 {
        figure {
          box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.25);
          background-color: $white;

          ul {
            &.tags {
              li {
                background-color: black;
                color: $white;
              }
            }
          }

          figcaption {
            background-color: rgba(mat.get-color-from-palette($primary, 400), 0.8);

            p {
              color: white;
            }
          }
        }
      }

      .mat-table {
        overflow-x: hidden;
        width: 90%;
        tr {
          &:nth-child(odd) {
            background-color: rgba(mat.get-color-from-palette($primary, 200), 0.5);

            td.mat-header-cell {
              color: white;
            }
          }

          .mat-header-cell {
            background-color: mat.get-color-from-palette($accent);
            color: white;
          }

          .mat-header-cell:first-child {
            max-width: 20px;
          }

          td.mat-cell {
            border-bottom: 0;
            border-right: 1px solid mat.get-color-from-palette($primary, 600);
            color: mat.get-color-from-palette($primary, 600);

            &:last-child {
              border-right: 0;
            }
          }

          &:last-child {
            td.mat-cell {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .ngx-pagination {
      li {
        &.pagination-previous {
          a:before,
          &:before {
            background-color: mat.get-color-from-palette($accent);
          }

          &:hover {
            a:before {
              background-color: mat.get-color-from-palette($accent, 200);
            }
          }
        }

        &.pagination-next {
          a:after,
          &:after {
            background-color: mat.get-color-from-palette($accent);
          }

          &:hover {
            a:after {
              background-color: mat.get-color-from-palette($accent, 200);
            }
          }
        }

        &.current {
          background-color: transparent;
          color: white;

          &:before {
            background-color: mat.get-color-from-palette($accent);
          }
        }

        a {
          color: black;

          &:hover {
            background-color: transparent;
            color: mat.get-color-from-palette($accent);
          }
        }
      }
    }
  }

  .mat-spinner {
    circle {
      stroke: mat.get-color-from-palette($accent);
    }
  }
}
