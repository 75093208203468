@use '@angular/material' as mat;
@import 'bootstrap/scss/bootstrap';

$toolbar-breakpoint: 600px;

$fa-font-path: '@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$font-family-base: 'Montserrat', sans-serif;

$success-colors: mat.define-palette(mat.$green-palette, 400);
$warning-colors: mat.define-palette(mat.$amber-palette, 400);

$spacer: 1rem;
$spacers: map-merge(
    (
        6: (
            $spacer * 3.5
        )
    ),
    $spacers
);

$patterns-yellow: #efb758;
$patterns-dark1: #0a0c11;
